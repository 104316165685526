<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container
  .row.justify-content-center.py-5.question
    .col-12.col-md-10
      //a.text-decoration-none(href="/quick-guide")
      //  .quick-guide.bg-black.text-primary.text-center.py-2.px-4.rounded.fw-bold Quick Start Tutorial Guide
      .accordion.accordion-flush
        template(v-for="help in searchFilterList")
          .accordion-item
            h2#flush-headingOnes.accordion-header
              button.accordion-button.collapsed.fw-bold(type='button' data-bs-toggle='collapse' :data-bs-target=" '#' + help.id" aria-expanded='false' aria-controls='flush-collapseOne')
                | {{ help.title }}
            .accordion-collapse.collapse(aria-labelledby='flush-headingOne' data-bs-parent='#accordionFlushExample' :id='help.id')
              template(v-for="sub in help.subtitle" )
                .accordion-body.ps-5.border-bottom
                  a.text-decoration-none.text-black(:href= " '/' + sub.url + useInMobileQueryString ") {{ sub.stitle }}

    .col-12.text-center.pt-5
      .contact-help Still need help?&nbsp;
        a.text-decoration-none.text-hyperlink(href="mailto:support@kloser.app") Contact us

Footer
</template>

<script>
import Navbar from "../components/TheNavbar";
import Footer from "../components/TheFooter";
export default {
  name: "Help",
  components: { Footer, Navbar },
  mounted() {
    this.isMobileApp = !!(
      this.$route.query && this.$route.query["is-mobile-app"]
    );
  },
  data() {
    return {
      isMobileApp: true,
      search: "",
      helpList: [
        {
          id: "gettingStarted",
          title: "Getting started",
          subtitle: [
            {
              stitle: "1.1 What is Kloser?",
              url: "what-is-kloser",
            },
            {
              stitle: "1.2 Create a Kloser Account",
              url: "kloser-account",
            },
            {
              stitle: "1.3 Forget password",
              url: "Kloser-pin",
            },
          ],
        },
        {
          id: "managingLeads",
          title: "Managing leads",
          subtitle: [
            {
              stitle: "2.1 How to import contact lists?",
              url: "Kloser-import-contact",
            },

            {
              stitle: "2.3 How to set up a lead group?",
              url: "Kloser-lead-group",
            },
            {
              stitle: "2.4 Lead details",
              url: "Kloser-lead-detail",
            },
            {
              stitle: "2.5  How to edit Lead details?",
              url: "Kloser-edit-lead-detail",
            },
            {
              stitle: "2.6  How to add Lead activity?",
              url: "Kloser-add-lead-activity",
            },
            {
              stitle: "2.7  How to edit Lead activity?",
              url: "Kloser-edit-lead-activity",
            },
            {
              stitle: "2.8  Bulk actions",
              url: "Kloser-bulk-action",
            },
            {
              stitle: "2.9  Message template",
              url: "Kloser-message-template",
            },
          ],
        },
        {
          id: "managingWebsite",
          title: "Managing website",
          subtitle: [
            {
              stitle: "3.1 How to Complete Your Profile?",
              url: "Kloser-complete-profile",
            },
            {
              stitle: "3.2 How to set up my product/service listings",
              url: "Kloser-product-listing",
            },
            {
              stitle: "3.3 Collection creation",
              url: "Kloser-collection-creation",
            },
            {
              stitle: "3.4 How to share website to your contacts",
              url: "Kloser-share-website",
            },
          ],
        },
        {
          id: "payment",
          title: "Payment/Subscription",
          subtitle: [
            {
              stitle: " 4.1 How to set up payment?",
              url: "Kloser-setup-payment",
            },
            {
              stitle: " 4.2 How to edit/remove cards?",
              url: "Kloser-edit-cards",
            },
            {
              stitle: " 4.3  Subscribe to Kloser",
              url: "Kloser-Subscribe",
            },
            {
              stitle: " 4.4 Cancelling your Subscription",
              url: "Kloser-cancel-Subscription",
            },
            {
              stitle: " 4.5 Can you refund me for my subscription?",
              url: "Kloser-refund-Subscription",
            },
          ],
        },
        {
          id: "dashboard",
          title: "Dashboard",
          subtitle: [
            {
              stitle: "5.1 Introduction to the dashboard",
              url: "Kloser-dashboard-introduction",
            },
          ],
        },
        {
          id: "account",
          title: "Account",
          subtitle: [
            {
              stitle: "6.1 Update account",
              url: "Kloser-update-account",
            },
          ],
        },
        {
          id: "advertisementTool",
          title: "Advertisement Tool",
          subtitle: [
            {
              stitle: "7.1 How to set up ads ",
              url: "Kloser-advertisement-tool",
            },
          ],
        },
      ],
    };
  },
  computed: {
    console: () => console,
    searchFilterList() {
      return this.helpList.filter((item) => {
        var displayItem = false;

        item.subtitle.forEach((sub) => {
          if (sub.stitle.toLowerCase().includes(this.search.toLowerCase())) {
            displayItem = true;
          }
        });

        return displayItem;
      });
    },
    useInMobileQueryString() {
      if (this.isMobileApp) {
        return "?is-mobile-app=true";
      } else {
        return "";
      }
    },
  },
};
</script>
